import './App.css';
import {useEffect, useState} from 'react';
import {BsClipboard, BsClipboardCheck, BsHandThumbsUp, BsHandThumbsDown} from 'react-icons/bs'

function App() {
const [data, setData] = useState(null);
const [loading, setLoading] = useState(true);
const [copied, setCopied] = useState(false);
const [alert, setAlert] = useState(false);
const [voteable, setVoteable] = useState(true);
const [votes, setVotes] = useState('');

const fetchData = async () => {
  setLoading(true)
  const response = await fetch("https://liiga.sieppi.net/random")
  const datay = await response.json()
  setData(datay)
  setVotes({yes:datay[0].votes.yes,no:datay[0].votes.no})
  console.log(datay)
  setCopied(false)
  setVoteable(true)
  setLoading(false)
}
const PatchVotes = (objid, vote) => {
  setVoteable(false)
  if (vote === 'yes') {
    setVotes({yes:votes.yes+1,no:votes.no})
  }
  if (vote === 'no') {
    setVotes({yes:votes.yes,no:votes.no+1})
  }
  fetch(`https://liiga.sieppi.net/random?id=${objid}&vote=${vote}`,{
  method: "PATCH"
})
}
useEffect(() => {
  fetchData()
}, [])

const CopytoClipboard = () => {
  navigator.clipboard.writeText(data[0].quote)
  setCopied(true)
  setAlert(true)
  setTimeout(() => {
    setAlert(false);
  }, 500);
}

const copy = copied ? <BsClipboardCheck /> : <BsClipboard />
  return (
    <div className="App w-full h-full">
      <h1 className='text-3xl font-bold py-8 md:py-14 text-white'>Goofies of them all</h1>
      
      <div className='relative mx-auto max-w-[80%] sm:max-w-md block p-6 rounded-lg border shadow-md bg-gray-800 border-gray-700 hover:bg-gray-700 whitespace-pre-line'>
      {loading && <h5 className='mb-2 text-lg font-bold tracking-tight text-white'>Loading...</h5>}
      {data && !loading &&(
        <>
        {alert && (<span className='absolute top-0 right-0 min-w-max text-green-200 sm:-mt-4 sm:-mr-14 -mt-4 mr-2'>Copied!</span>)}
      <span onClick={CopytoClipboard} className='absolute top-2 right-2 text-white'>{copy} </span>
      
      <h5 className='pb-12 overflow-hidden pt-4 text-xl font-bold tracking-tight text-white'>{data[0].quote}</h5>
      <p className='absolute text-sm text-white right-2'>{data[0].date}</p> 
      <div className="absolute flex left-0">
        <div className="flex ml-2 mr-2 md:mx-5 text-lg -mt-2 text-red-200 md:text-2xl">
        <p className='text-sm md:text-lg -mt-4 mr-1'>{votes.no}</p>
        <BsHandThumbsDown className={voteable ? 'cursor-pointer' : ''} onClick={voteable ? () => PatchVotes(data[0]._id, "no") : undefined }/>
        
        </div>
        <div className="flex text-lg md:text-2xl -mt-2 text-green-200">
        <p className='text-sm md:text-lg -mt-4 mr-1'>{votes.yes}</p>
        <BsHandThumbsUp className={voteable ? 'cursor-pointer' : ''} onClick={voteable ? () => PatchVotes(data[0]._id, "yes") : undefined }/>
        </div>
      </div>
      </>)
      }
      </div>
      <button className='block py-5 my-10 w-36 mx-auto rounded-lg p-4 bg-indigo-500 hover:bg-indigo-600 border border-indigo-500 hover:border-indigo-600 text-gray-200 text-center text-sm' onClick={() => fetchData()}>Random Goofism</button>
    </div>
  );
}

export default App;
